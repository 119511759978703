<template>
  <main>
    <div class="layout-subcontainer">
      <div class="box-wrap d-flex">
        <div class="col-6 pa-0">
          <h1 class="tit-h1 d-flex">메시지 관리
            <div class="ml-auto">
              <v-btn outlined small class="btn-etc2 ml-2">조회</v-btn>
            </div>
          </h1>
          <div class="box-ct mt-2">
            <div class="table-form d-flex">
              <table>
                <caption class="hide">
                  사용자정보 항목
                </caption>
                <colgroup>
                  <col width="148px" />
                  <col width="240px" />
                  <col width="148px" />
                  <col width="" />
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="row">
                      <v-badge dot color="#EF9191">건설사명</v-badge>
                    </th>
                    <td >
                      <div class="form-inp full">
                        <v-select
                          :items="DROPITEMS_1"
                          item-text="title"
                          outlined
                          hide-details
                          placeholder="선택하세요"
                        >
                        </v-select>
                      </div>
                    </td>
                    <th scope="row">
                      <v-badge dot color="#EF9191">사업자명</v-badge>
                    </th>
                    <td>
                      <div class="form-inp full">
                        <v-select
                          :items="DROPITEMS_2"
                          item-text="title"
                          outlined
                          hide-details
                          placeholder="선택하세요"
                        >
                        </v-select>
                      </div>
                    </td>

                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="box-ct mt-3">
            <h1 class="tit-h1 d-flex">알림톡 목록

            </h1>
            <div class="mt-2">
              <v-data-table
                dense
                height="610px"
                fixed-header
                hide-default-footer
                :headers="gridDataHeaders"
                :items="FnRowNum(gridDataText)"
                :items-per-page="30"
                item-key="name"
                :page.sync="page"
                class="grid-default"
                @page-count="pageCount = $event"
              ></v-data-table>
              <div class="grid-paging text-center pt-2">
                <span class="grid-total-count">총 <strong>{{ gridDataText.length }}</strong>건</span>
                <v-pagination
                  v-model="page"
                  :length="pageCount"
                  :total-visible="totalVisible"
                  next-icon="svg-paging-next"
                  prev-icon="svg-paging-prev"
                ></v-pagination>
              </div>
            </div>
          </div>

        </div>
        <!-- 알림톡 상세내용 -->
        <div class="ml-6 flex-grow-1">
          <h1 class="tit-h1 d-flex">알림톡 상세내용
            <div class="ml-auto">
              <v-btn outlined small class="btn-point ml-2">저장</v-btn>
            </div>
          </h1>
          <div class="box-ct mt-2">
            <div class="table-form d-flex">
              <table>
                <caption class="hide">
                  사용자정보 항목
                </caption>
                <colgroup>
                  <col width="148px" />
                  <col width="300px" />
                  <col width="148px" />
                  <col width="" />
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="row">
                      <v-badge dot color="#EF9191">건설사명</v-badge>
                    </th>
                    <td >
                      <div class="form-inp full">
                        <v-select
                          :items="DROPITEMS_1"
                          item-text="title"
                          outlined
                          hide-details
                          placeholder="선택하세요"
                        >
                        </v-select>
                      </div>
                    </td>
                    <th scope="row">
                      <v-badge dot color="#EF9191">사업자명</v-badge>
                    </th>
                    <td>
                      <div class="form-inp full">
                        <v-select
                          :items="DROPITEMS_1"
                          item-text="title"
                          outlined
                          hide-details
                          placeholder="선택하세요"
                        >
                        </v-select>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      채널 ID
                    </th>
                    <td>
                      <v-text-field
                        class="form-inp full"
                        name="name" label="" outlined >
                      </v-text-field>
                    </td>
                    <th scope="row">
                      TPL코드
                    </th>
                    <td>
                      <v-text-field
                        class="form-inp full"
                        name="name" label="" outlined >
                      </v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">발송키값</th>
                    <td>
                      <v-text-field
                        class="form-inp full"
                        name="name" label="" outlined >
                      </v-text-field>
                    </td>
                    <th scope="row">발송전화번호</th>
                    <td>
                      <v-text-field
                        class="form-inp full"
                        name="name" label="" outlined >
                      </v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <v-badge dot color="#EF9191">알림톡유형</v-badge>
                    </th>
                    <td>
                      <div class="form-inp full">
                        <v-select
                          :items="DROPITEMS_1"
                          item-text="title"
                          outlined
                          hide-details
                          placeholder="선택하세요"
                        >
                        </v-select>
                      </div>
                    </td>
                    <th scope="row">템플릿유형</th>
                    <td>
                      <div class="form-inp full">
                        <v-select
                          :items="DROPITEMS_1"
                          item-text="title"
                          outlined
                          hide-details
                          placeholder="선택하세요"
                        >
                        </v-select>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">템플릿명</th>
                    <td colspan="3">
                      <v-text-field
                        class="form-inp full"
                        name="name" label="" outlined >
                      </v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">메시지상태유형</th>
                    <td>
                      <div class="form-inp full">
                        <v-select
                          :items="DROPITEMS_1"
                          item-text="title"
                          outlined
                          hide-details
                          placeholder="선택하세요"
                        >
                        </v-select>
                      </div>
                    </td>
                    <th scope="row">상태</th>
                    <td>
                      <div class="form-inp full">
                        <v-select
                          :items="DROPITEMS_1"
                          item-text="title"
                          outlined
                          hide-details
                          placeholder="선택하세요"
                        >
                        </v-select>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">템플릿내용</th>
                    <td colspan="3">
                      <v-textarea
                        class="textarea-type0"
                        label=""
                        no-resize
                        outlined
                      ></v-textarea>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="box-ct mt-3">
            <h1 class="tit-h1 d-flex">알림톡 버튼목록
              <div class="d-flex ml-auto">
                <v-btn outlined small class="btn-default">신규</v-btn>
                <v-btn outlined small class="btn-etc ml-2">삭제</v-btn>
              </div>
            </h1>
            <div class="mt-2">
              <v-data-table
                dense
                height="300px"
                fixed-header
                hide-default-footer
                :headers="gridDataHeaders2"
                :items="FnRowNum(gridDataText2)"
                :items-per-page="30"
                item-key="name"
                :page.sync="page"
                class="grid-default"
                @page-count="pageCount = $event"
              ></v-data-table>
              <div class="grid-paging text-center pt-2">
                <span class="grid-total-count">총 <strong>{{ gridDataText.length }}</strong>건</span>
                <v-pagination
                  v-model="page"
                  :length="pageCount"
                  :total-visible="totalVisible"
                  next-icon="svg-paging-next"
                  prev-icon="svg-paging-prev"
                ></v-pagination>
              </div>
            </div>
             </div>

        </div>
      </div>

    </div>

  </main>
</template>

<script>
  export default {
    name: "MENU_CAM0300_pu", //name은 'MENU_' + 파일명 조합
    components: {
    },
    data() {
      return {
        menu1: false,
        menu2: false,
        menu3: false,
        dates: [
          (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
          (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
        ],
        gridDataHeaders: [
          { text: 'NO', value: 'index', align: 'center',width: '60px'  },
          { text: '채널ID', value: 'index01', align: 'center'},
          { text: '사업지명', value: 'index02', align: 'center'},
          { text: 'TPL', value: 'index03', align: 'center'},
          { text: '템플릿명', value: 'index04', align: 'center'},
          { text: '메시지상태유형', value: 'index05', align: 'center'},
          { text: '사용여부', value: 'index06', align: 'center'},
        ],

        gridDataText: [

          {
            index01: 'index01',
            index02: 'index02',
            index03: 'index03',
            index04: 'index04',
            index05: 'index05',
            index06: 'index06',
          },
          {
            index01: 'index01',
            index02: 'index02',
            index03: 'index03',
            index04: 'index04',
            index05: 'index05',
            index06: 'index06',
          },
          {
            index01: 'index01',
            index02: 'index02',
            index03: 'index03',
            index04: 'index04',
            index05: 'index05',
            index06: 'index06',
          },
          {
            index01: 'index01',
            index02: 'index02',
            index03: 'index03',
            index04: 'index04',
            index05: 'index05',
            index06: 'index06',
          },
          {
            index01: 'index01',
            index02: 'index02',
            index03: 'index03',
            index04: 'index04',
            index05: 'index05',
            index06: 'index06',
          },
          {
            index01: 'index1111111',
            index02: 'index02',
            index03: 'index03',
            index04: 'index04',
            index05: 'index05',
            index06: 'index06',
          },
        ],
        gridDataHeaders2: [
          { text: 'NO', value: 'index', align: 'center',width: '60px'  },
          { text: '정렬순서', value: 'index01', align: 'center'},
          { text: '사업지명', value: 'index02', align: 'center'},
          { text: '버튼유형', value: 'index03', align: 'center'},
          { text: '버튼명', value: 'index04', align: 'center'},
          { text: '버튼링크유형', value: 'index05', align: 'center'},
          { text: '버튼링크URL', value: 'index06', align: 'center'},
        ],
        gridDataText2: [
          {
            index01: 'index01',
            index02: 'index02',
            index03: 'index03',
            index04: 'index04',
            index05: 'index05',
            index06: 'index06',
          },
          {
            index01: 'index01',
            index02: 'index02',
            index03: 'index03',
            index04: 'index04',
            index05: 'index05',
            index06: 'index06',
          },
          {
            index01: 'index01',
            index02: 'index02',
            index03: 'index03',
            index04: 'index04',
            index05: 'index05',
            index06: 'index06',
          },
        ],
        page: 1,
        pageCount: 0,
        itemsPerPage: 5,
        totalVisible: 10,

      };
    },

    mounted() {
    },

    methods: {
      FnRowNum(data) {
        return data.map(
          (data, index) => ({
          ...data,
          index: index + 1
        }))
      },
      formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${year}/${month}/${day}`
      },
      startDate(e){
        this.dates[0] = e;
      },
      endDate(e){
        this.dates[1] = e;
      },

    },

    computed: {
      dateRangeText () {
        var newStartDate = this.dates[0];
        var newEndDate = this.dates[1];
        return newStartDate + ' ~ ' + newEndDate;
        // return this.dates.join(' ~ ')
      },

    },
  };
</script>